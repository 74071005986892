import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Header from './Header.js';
import { HelmetProvider } from 'react-helmet-async';
import { X } from 'lucide-react';
import Rankings from './Rankings.js';
import Home from './Home.js';
import FAQ from './FAQ.js';
import GameRecommendations from './GameRecommendations.js';

// Navigation items
const navItems = [
  { path: "/", label: "Home", element: <Home /> },
  { path: "/rankings", label: "Game Rankings", element: <Rankings /> },
  { path: "/recommendations", label: "Recommendations", element: <GameRecommendations /> },
  { path: "/faq", label: "FAQ", element: <FAQ /> },
];

// NavLink Component
const NavLink = ({ to, children, onClick, className }) => (
  <Link to={to} className={`${className} hover:text-slate-300`} onClick={onClick}>
    {children}
  </Link>
);

// MobileMenu Component
const MobileMenu = ({ isOpen, toggleMenu }) => (
  <>
    <div 
      className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      onClick={toggleMenu}
    />
    <div 
      className={`fixed inset-y-0 left-0 w-1/2 bg-slate-800 z-50 transition-transform duration-300 ease-in-out transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="flex justify-end p-4">
        <button onClick={toggleMenu} className="text-white">
          <X size={24} />
        </button>
      </div>
      <nav className="flex flex-col items-center">
        {navItems.map((item) => (
          <NavLink key={item.path} to={item.path} onClick={toggleMenu} className="text-white py-2">
            {item.label}
          </NavLink>
        ))}
      </nav>
    </div>
  </>
);

// Updated Navigation Component
const Navigation = ({ isMenuOpen, toggleMenu }) => {
  return (
    <nav className="hidden md:block bg-slate-700 text-slate-100 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex space-x-4">
          {navItems.map((item) => (
            <NavLink key={item.path} to={item.path}>
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

// Main App Component
const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <HelmetProvider>
      <Router>
        <div className="font-sans bg-gray-100 min-h-screen">
          <div className="bg-slate-700 text-slate-100">
            <div className="container mx-auto">
              <Header title="Solo Sleuth" subtitle="Find your next favorite solo board game" onMenuClick={toggleMenu} />
            </div>
            <Navigation isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
          </div>
          <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
          <main>
            <Routes>
              {navItems.map((item) => (
                <Route key={item.path} path={item.path} element={item.element} />
              ))}
            </Routes>
          </main>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;