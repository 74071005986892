import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useLocation, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  CircularProgress
} from '@mui/material';
import SearchBar from './SearchBar.js';
import FilterSection from './FilterSection.js';
import GameList from "./GameList.js";

const currentYear = new Date().getFullYear();

const Rankings = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [filters, setFilters] = useState({
        mechanics: [],
        categories: [],
        families: [],
        years: [],
        minAvgWeight: null,
        maxAvgWeight: null,
        nameSearchTerm: '',
        minPlayingTime: '',
        maxPlayingTime: ''
    });
    const [searchInputValue, setSearchInputValue] = useState('');
    const [allMechanics, setAllMechanics] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [allFamilies, setAllFamilies] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [isLoading, setIsLoading] = useState(true);
    const [isFiltersReady, setIsFiltersReady] = useState(false);
    const [resetPageTrigger, setResetPageTrigger] = useState(0);

    const updateFiltersFromParams = useCallback(() => {
        const newFilters = {
            mechanics: searchParams.getAll('mechanics'),
            categories: searchParams.getAll('categories'),
            families: searchParams.getAll('families'),
            years: searchParams.getAll('years'),
            minAvgWeight: searchParams.get('minWeight') ? Number(searchParams.get('minWeight')) : null,
            maxAvgWeight: searchParams.get('maxWeight') ? Number(searchParams.get('maxWeight')) : null,
            nameSearchTerm: searchParams.get('search') || '',
            minPlayingTime: searchParams.get('minPlayingTime') || '',
            maxPlayingTime: searchParams.get('maxPlayingTime') || ''
        };

        setFilters(newFilters);
        setSearchInputValue(newFilters.nameSearchTerm);
        setItemsPerPage(Number(searchParams.get('itemsPerPage') || 25));
        setIsFiltersReady(true);
    }, [searchParams]);

    useEffect(() => {
        const fetchMechanicsCategoriesAndFamilies = async () => {
            setIsLoading(true);
            try {
                const [mechanicsResponse, categoriesResponse, familiesResponse] = await Promise.all([
                    axios.get('/api/games/mechanics'),
                    axios.get('/api/games/categories'),
                    axios.get('/api/games/families')
                ]);
                // Ensure we're setting arrays of strings
                setAllMechanics(mechanicsResponse.data.map(m => m.name || m));
                setAllCategories(categoriesResponse.data.map(c => c.name || c));
                setAllFamilies(familiesResponse.data.content.map(family => family.name));
            } catch (error) {
                console.error('Error fetching mechanics, categories, and families:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMechanicsCategoriesAndFamilies();
    }, []);

    useEffect(() => {
        setIsFiltersReady(false);
        updateFiltersFromParams();
    }, [location, updateFiltersFromParams]);

    const updateSearchParams = useCallback((newFilters, newItemsPerPage = itemsPerPage) => {
        const params = new URLSearchParams(searchParams);
        if (newFilters.nameSearchTerm) {
            params.set('search', newFilters.nameSearchTerm);
        } else {
            params.delete('search');
        }
        params.delete('mechanics');
        params.delete('categories');
        params.delete('families');
        params.delete('years');
        newFilters.mechanics.forEach(m => params.append('mechanics', m));
        newFilters.categories.forEach(c => params.append('categories', c));
        newFilters.families.forEach(f => params.append('families', f));
        newFilters.years.forEach(y => params.append('years', y));
        if (newFilters.minAvgWeight !== null) {
            params.set('minWeight', newFilters.minAvgWeight);
        } else {
            params.delete('minWeight');
        }
        if (newFilters.maxAvgWeight !== null) {
            params.set('maxWeight', newFilters.maxAvgWeight);
        } else {
            params.delete('maxWeight');
        }
        params.set('itemsPerPage', newItemsPerPage);
        if (newFilters.minPlayingTime) {
            params.set('minPlayingTime', newFilters.minPlayingTime);
        } else {
            params.delete('minPlayingTime');
        }
        if (newFilters.maxPlayingTime) {
            params.set('maxPlayingTime', newFilters.maxPlayingTime);
        } else {
            params.delete('maxPlayingTime');
        }
        setSearchParams(params, { replace: true });
        setResetPageTrigger(prev => prev + 1);
    }, [searchParams, itemsPerPage, setSearchParams]);

    const handleSearch = (searchTerm) => {
        const newFilters = { ...filters, nameSearchTerm: searchTerm };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const handleInputChange = (newValue) => {
        setSearchInputValue(newValue);
    };

    const handleFilterChange = (type, value) => {
        const newFilters = { ...filters, [type]: value };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const handleWeightChange = (event, newValue) => {
        const newFilters = { 
            ...filters, 
            minAvgWeight: newValue[0] === 1 ? null : newValue[0], 
            maxAvgWeight: newValue[1] === 5 ? null : newValue[1] 
        };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const removeFilter = (type, valueToRemove) => {
        const newFilters = {
            ...filters,
            [type]: filters[type].filter(value => value !== valueToRemove)
        };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const applySuggestedFilter = (filterKey, filterValue) => {
        const newFilters = {
            ...filters,
            [filterKey]: Array.isArray(filters[filterKey])
                ? [...filters[filterKey], filterValue]
                : filterValue
        };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const clearAllFilters = () => {
        const newFilters = {
            mechanics: [],
            categories: [],
            families: [],
            years: [],
            minAvgWeight: null,
            maxAvgWeight: null,
            nameSearchTerm: '',
            minPlayingTime: '',
            maxPlayingTime: ''
        };
        setFilters(newFilters);
        setSearchInputValue('');
        updateSearchParams(newFilters);
    };

    const clearSearch = () => {
        setSearchInputValue('');
        const newFilters = { ...filters, nameSearchTerm: '' };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    };

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(newItemsPerPage);
        updateSearchParams(filters, newItemsPerPage);
    };

    const handleFamilySearch = async (searchTerm) => {
        try {
            const response = await axios.get(`/api/games/families?searchTerm=${searchTerm}`);
            return response.data.content.map(family => family.name);
        } catch (error) {
            console.error('Error searching families:', error);
            return [];
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <Box className="bg-white shadow-sm rounded-lg overflow-hidden mb-8 p-6">
                <h2 className="text-2xl font-semibold text-slate-800 mb-4">Solo Game Rankings</h2>
                <Typography variant="body1" component="p" className="text-gray-700 mb-4">
                    Below are our rankings of the best solo games. You can filter on mechanics, 
                    categories, families, weight, playing time, and year to find the perfect game for you. Visit{' '}
                    <RouterLink to="/faq" className="text-blue-600 hover:underline">the FAQ</RouterLink> to 
                    learn more about how these rankings are calculated.
                </Typography>
            </Box>

            <SearchBar
                searchInputValue={searchInputValue}
                onSearch={handleSearch}
                onInputChange={handleInputChange}
                onClearSearch={clearSearch}
            />

            <FilterSection
                filters={filters}
                allMechanics={allMechanics}
                allCategories={allCategories}
                allFamilies={allFamilies}
                itemsPerPage={itemsPerPage}
                onFilterChange={handleFilterChange}
                onWeightChange={handleWeightChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                onClearAllFilters={clearAllFilters}
                onApplySuggestedFilter={applySuggestedFilter}
                removeFilter={removeFilter}
                currentYear={currentYear}
                onFamilySearch={handleFamilySearch}
            />

            {isLoading ? (
                <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                </Box>
            ) : isFiltersReady ? (
                <GameList 
                    apiUrl='/api/games/solo' 
                    filters={filters} 
                    itemsPerPage={itemsPerPage}
                    showDescription={true}
                    resetPageTrigger={resetPageTrigger}
                    showShareButton={true}
                />
            ) : (
                <Box display="flex" justifyContent="center" my={4}>
                    <Typography>Applying filters...</Typography>
                </Box>
            )}
        </div>
    );
};

export default Rankings;