import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Search, Menu } from 'lucide-react';

const Header = ({ title, subtitle, onMenuClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm.length < 2) {
        setSuggestions([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await fetch('/api/games/solo?page=0&size=5', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nameSearchTerm: searchTerm
          }),
        });
        const data = await response.json();
        setSuggestions(data.content.map(game => game.name));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const debounceTimer = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setSearchTerm('');
    setShowSuggestions(false);
  }, [location]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/rankings?search=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setShowSuggestions(false);
    navigate(`/rankings?search=${encodeURIComponent(suggestion)}`);
  };

  return (
    <header className="bg-slate-700 text-slate-100 py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
          <div className="flex items-center w-full md:w-auto">
            <button onClick={onMenuClick} className="md:hidden text-white mr-4">
              <Menu size={24} />
            </button>
            <Link to="/" className="flex items-center space-x-3 group">
              <img 
                src="/solo_sleuth_logo.svg" 
                alt="Solo Sleuth Logo" 
                className="w-10 h-10 transform group-hover:scale-110 transition-transform duration-200 filter brightness-0 invert"
              />
              <div>
                <h1 className="text-2xl font-bold leading-tight group-hover:text-slate-300 transition-colors duration-200">
                  {title}
                </h1>
                <p className="text-sm text-slate-300 italic leading-tight">
                  {subtitle}
                </p>
              </div>
            </Link>
          </div>
          <div className="w-full md:w-64 relative" ref={searchRef}>
            <form onSubmit={handleSearchSubmit}>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search games..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full py-2 px-4 pr-10 rounded-full bg-slate-600 text-slate-100 placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400"
                />
                <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-400">
                  <Search size={20} />
                </button>
              </div>
            </form>
            {showSuggestions && suggestions.length > 0 && (
              <ul className="absolute z-10 left-0 right-0 mt-1 bg-slate-600 rounded-md shadow-lg max-h-60 overflow-auto">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="px-4 py-2 hover:bg-slate-500 cursor-pointer"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            {isLoading && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-slate-400"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;